import { render, staticRenderFns } from "./default.vue?vue&type=template&id=e81663a6&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=e81663a6&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBar: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/TopBar.vue').default,Footer: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/Footer.vue').default,BottomBar: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/BottomBar.vue').default,VodalMain: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/VodalMain.vue').default})
