
import { is_running_in_pwa } from '~/lib/util/util';

// NuxtLink wrapper compatible with PWA used to prevent opening browser window with
// target=_blank while in PWA context.
const is_pwa = is_running_in_pwa();

export default {
  name: 'AppLink',
  props: {
    to: {
      required: true,
    },
    target: {
      type: String
    },
    title: {
      type: String,
    },
  },
  computed: {
    target_() {
      if(this.target === '_blank') {
        if(is_pwa) {
          return '_self'
        } else {
          return '_blank'
        }
      } else {
        return this.target;
      }
    },
  }
}
