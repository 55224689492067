// import * as faker from 'faker';
import _ from 'lodash';
import {isMobile} from 'mobile-device-detect';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {shopService} from '~/lib/accessors/api';
import {emptyFetchPage, FetchPage} from '~/lib/api/types/FetchPage';
import {ShopCategory} from '~/lib/api/types/ShopCategory';
import appConfig from '~/lib/util/appConfig';
import {picsum} from '~/lib/util/picsum';
import {ErrorCatcherParentGetter, ErrorCatcherParentGetterInit, InitialCollectionStoreObj, NextPageOpts} from '~/store/ChildSearch';
import ErrorBroker from '~/store/ErrorBroker';


const defaultFetchPage = emptyFetchPage(isMobile ? 6 : 24)

@Module({
  name: 'ShopsStore',
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class ShopsStore extends VuexModule {
  categories: ShopCategory[]             = []
  selectedCategory: ShopCategory | null  = null
  items: Shop[]                          = []
  fetchPage: FetchPage                   = emptyFetchPage()
  hasNextPage: boolean                   = true
  errorCatcher: ErrorCatcherParentGetter = ErrorCatcherParentGetterInit();

  @Action({rawError: true})
  async initializeShopStore(obj: InitialCollectionStoreObj) {
    let fetchPage  = _.cloneDeep(obj.fetchPage ? obj.fetchPage : defaultFetchPage);
    let {isFirstSearch} = obj;

    fetchPage.page = 0;
    this.setFetchPage(fetchPage);
    this.setErrorCatcher(obj.errorCatcher ? _.clone(obj.errorCatcher) : {vm: () => undefined});

    if (this.categories.length === 0) {

      try {
        if (appConfig.env === 'mock') {
          this.setCategories(fakeCategories());
        } else {
          const categories = await shopService
            .withErrorBroker()
            .getShopCategoryList()
          this.setCategories(categories);
        }
      } catch (err) {
        ErrorBroker.CatchError(err, this.errorCatcher.vm());
      }
    }

    this.clear();
    this.getNextPage({isFirstSearch});
  }


  @Mutation
  setErrorCatcher(errorCatcher: ErrorCatcherParentGetter) {
    this.errorCatcher = errorCatcher;
  }

  @Action
  search(searchText: string) {
    this.setSearchText(searchText);
    this.initializeShopStore({fetchPage: this.fetchPage, errorCatcher: this.errorCatcher, isFirstSearch: true})
  }

  @Action
  searchShopCategory(category: ShopCategory | null) {
    this.setSelectedCategory(category);
    this.initializeShopStore({fetchPage: this.fetchPage, errorCatcher: this.errorCatcher})
  }

  @Mutation
  setSelectedCategory(selectedCategory: ShopCategory | null) {
    this.selectedCategory = selectedCategory;
  }

  @Mutation
  setCategories(categories: ShopCategory[]) {
    this.categories = categories;
  }

  @Mutation
  setSearchText(searchText: string) {
    this.fetchPage.search = searchText;
  }

  // -----------------------
  // BASE FETCH PAGE METHODS
  // -----------------------

  @Action
  async getNextPage({isFirstSearch}: NextPageOpts = {}) {
    if (!this.hasNextPage) {
      console.warn('no next page for ChildSearch');
      return;
    }

    this.incrPage()
    try {
      if (appConfig.env === 'mock') {
        this.addItems(fakeShops(this.fetchPage));
      } else {
        const page = await shopService
          .withErrorBroker()
          .getShopList(this.fetchPage, this.selectedCategory);
        this.setHasNextPage(!!page.next);

        // Fix KIPR-33
        // -----------
        // Fixes race condition when two requests are pending and we duplicate results in the
        // output. Otherwise we could consider some kind of Set instead of list of shops.
        if(isFirstSearch) {
          this.clear();
        }

        this.addItems(page.results);
      }
    } catch (err) {
      ErrorBroker.CatchError(err, this.errorCatcher.vm());
    }

  }

  @Mutation
  setFetchPage(fetchPage: FetchPage) {
    this.fetchPage = fetchPage;
  }

  @Mutation
  incrPage() {
    this.fetchPage.page++;
  }

  @Mutation
  resetPage() {
    this.fetchPage.page = 0;
  }

  @Mutation
  clear() {
    this.hasNextPage = true;
    this.items       = [];
  }

  @Mutation
  addItems(shops: Shop[]) {
    this.items = this.items.concat(shops);
  }

  @Mutation
  setHasNextPage(hasNextPage: boolean) {
    this.hasNextPage = hasNextPage;
  }
}


function fakeShops(fetchPage: FetchPage): Shop[] {
  // return _.range(0, fetchPage.page_size).map(i => {
  //   return fakeShop()
  // })
  // @ts-ignore
  return _(_.range(0, 5 * fetchPage.page_size)).map(i => {
    return fakeShop()
    // @ts-ignore
  }).filter(shop => {
    return shop.name.match(new RegExp(fetchPage.search));
  }).take(fetchPage.page_size).value()
}


function fakeShop(): Shop {
  return {
    // @ts-ignore
    id: faker.datatype.number(10000000),
    // @ts-ignore
    name: faker.company.companyName(),
    redirect_url: '/',
    // @ts-ignore
    userId: faker.datatype.number(100000) + '',
    logo: picsum(1024, 1024, true),
    logo_thumb_desktop: picsum(200, 200, true),
    logo_thumb_mobile: picsum(1024, 1024, true),
    domain: '',
    margin_percent: '',
    margin: '',
    vat_kind: '',
  };
}


function fakeCategories(): ShopCategory[] {
  // @ts-ignore
  return faker.datatype.array(25).map(i => {
    return fakeCategory()
  })
}


function fakeCategory(): ShopCategory {
  return {
    // @ts-ignore
    id: faker.datatype.number(100000),
    // @ts-ignore
    name: faker.lorem.words(2),
  };
}
