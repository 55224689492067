import {BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {FetchPage} from '~/lib/api/types/FetchPage';
import {PageResponse} from '~/lib/api/types/PageResponse';
import {ShopCategory} from '~/lib/api/types/ShopCategory';


export default class ShopApiClient extends BaseApiClient {
  // getShopList(page: any, search: any, category: any, pageSize = 8) {
  getShopList(fetchPage: FetchPage, category: ShopCategory | null) {
    let categoryFilter = ''
    if (category?.id) {
      categoryFilter = `&categories=${category.id}`
    }
    return this.$get<PageResponse<Shop>>(
      `/shops/?page=${fetchPage.page}&page_size=${fetchPage.page_size}&search=${fetchPage.search}${categoryFilter}`
    )
  }

  getLandingPageShops() {
    return this.$get<PageResponse<Shop>>(
      `/shops/?page=1&page_size=200&show_on_landing_page_banner=True`
    );
  }

  getShopById(id: number) {
    return this.$get<PageResponse<Shop>>(
      `/shops/?page=1&page_size=1&id=${id}`
    );
  }

  getShopCategoryList() {
    return this.$get<ShopCategory[]>(`/categories/`)
  }
}
