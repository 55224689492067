import {Store} from 'vuex'
import {getModule} from 'vuex-module-decorators'
import ChildGetSingle from '~/store/ChildGetSingle';
import ChildProfileStore from '~/store/ChildProfileStore';
import ChildSearch from '~/store/ChildSearch';
import FilledDocumentsStore from '~/store/FilledDocumentsStore';
import GlobalStore from '~/store/GlobalStore';
import ShopsStore from '~/store/ShopsStore';
import TransactionsStore from '~/store/TransactionsStore';
import TransactionInfoPopupStore from '~/store/TransactionInfoPopupStore';


let childSearchStore: ChildSearch
let childGetSingleStore: ChildGetSingle
let childProfileStore: ChildProfileStore
let shopsStore: ShopsStore
let transactionsStore: TransactionsStore
let filledDocumentsStore: FilledDocumentsStore
let globalStore: GlobalStore
let transactionInfoPopupStore: TransactionInfoPopupStore


function initialiseStores(store: Store<any>): void {
  childSearchStore = getModule(ChildSearch, store)
  childGetSingleStore = getModule(ChildGetSingle, store)
  shopsStore = getModule(ShopsStore, store)
  transactionsStore = getModule(TransactionsStore, store)
  filledDocumentsStore = getModule(FilledDocumentsStore, store);
  childProfileStore = getModule(ChildProfileStore, store)
  globalStore = getModule(GlobalStore, store)
  transactionInfoPopupStore = getModule(TransactionInfoPopupStore, store)
}


export {
  initialiseStores,
  childSearchStore,
  childGetSingleStore,
  childProfileStore,
  shopsStore,
  transactionsStore,
  filledDocumentsStore,
  transactionInfoPopupStore,
  globalStore
}
