
import _ from 'lodash';
import Vue from 'vue';
import { commonService } from '~/lib/accessors/api';
import { childProfileStore } from '~/lib/accessors/store-accessor';

import {CHOOSE_CHILD} from '~/lib/constants';

// @ts-ignore
import HamburgerMenuIcon from '~/assets/icons/hamburger-menu-icon.svg?inline';
// @ts-ignore
import CloseMenuIcon from '~/assets/icons/close-menu-icon.svg?inline';


// If we set components directly then vscode typing stops working in computed
// properties e.g. it says `isLoggedIn` is unknown property of this.
function getMixin(): {} {
  return {
    components: {
      HamburgerMenuIcon,
      CloseMenuIcon
    }
  }
}

export default Vue.extend({
  data() {
    return {
      mobileMenuVisible: false,
      menuAlertVisible: false,  // TODO

      // Is given dropdown visible?
      menuDropdownState: [false, false, false]
    }
  },
  ...getMixin(),
  methods: {
    omit(obj: any, keys: string[]) {
      return _.omit(obj, keys);
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    },
    closeMobileMenu() {
      this.mobileMenuVisible = false;
    }
  },
  computed: {
    anyDropdownVisible() {
      // @ts-ignore
      return this.menuDropdownState.some(v => v);
    },
    chcePomagacQuery(): any {
      const ret = {} as any;
      ret[CHOOSE_CHILD] = null;
      return ret;
    },
    isLoggedIn(): boolean {
      return this.$auth.loggedIn;
    },
    isParent(): boolean {
      return this.$user().role === "parent";
    },
    userRole() {
      return this.$user().role;
    },
    isProfileComplete() {
      return this.$auth.user ? this.$auth.user.is_profile_complete : true;
    },
    impersonating() {
      return this.$user().is_impersonating;
    },
    yourAccountButton() {
      if(! this.isLoggedIn) {
        console.error('yourAccountButton called when not logged in');
        return;
      }

      if(this.userRole === 'parent') {
        return {
          to: {
            name: 'zalogowany-opiekun',
          },

          // @ts-ignore
          displayName: this.isProfileComplete ? 'Twoje konto' : 'Uzupełnij profil',

          // @ts-ignore
          alert: ! this.isProfileComplete,
          onClick: () => {
            if(! this.isProfileComplete) {
              childProfileStore.openCompleteYourProfileMenu();
            }
          }
        }
      } else if(this.userRole === 'helper') {
        return {
          to: {name: 'zalogowany-pomagajacy'},
          displayName: 'Twoje konto'
        }
      } else {
        return;
      }
    },
    loggedInMenu() {
      let menu = [];

      // @ts-ignore
      menu.push(this.yourAccountButton);

      if(this.userRole === "parent" || this.userRole === "helper") {
        menu.push({
          to: {name: 'zmiana-hasla'},
          displayName: 'Zmień hasło'
        });
      }

      if(this.impersonating) {
        menu.push({
          displayName: 'Zakończ impersonację',
          class: 'text-red-200-important',

          // stop impersonation
          onClick: async () => {
            await commonService.withContext(this).stopImpersonation()

            // We redirect to the main page, so we don't end up on a page that is supposed
            // to work only for impersonated users (e.g. zalogowany-opiekun). We do it
            // with full page reload to make sure new authentication information is
            // loaded.
            const l = window.location;
            window.location.href = `${l.protocol}//${l.host}`;
          }
        });
      }

      menu.push({
        onClick: (e: Event) => {
          console.log('logout!');
          e.preventDefault();
          this.$authExecuteLogout();
        },
        displayName: 'Wyloguj'
      });

      return menu.filter(m => !!m);
    },
    loggedInNavigation(): LoggedInNavigationMenuLink[] {
      const menu: LoggedInNavigationMenuLink[] = [];

      if(! this.isLoggedIn) {
        return [];
      }

      const role = this.userRole;

      menu.push({
        name: 'mobile_separator',
        type: 'mobile_separator',
        class: 'mt-3 mb-4 border-t border-white hidden xs:block',
      });

      if(role === "parent") {
        menu.push({
          name: 'zalogowany-opiekun',
          displayName: this.isProfileComplete ? 'Twoje konto' : 'Uzupełnij profil',
          alert: ! this.isProfileComplete,
          onClick: () => {
            if(! this.isProfileComplete) {
              childProfileStore.openCompleteYourProfileMenu();
            }
          }
        });
      }

      if(role === "helper") {
        menu.push({
          name: 'zalogowany-pomagajacy',
          displayName: 'Twoje konto'
        });
      }

      if(role === "parent" || role === "helper") {
        menu.push({
          name: 'zmiana-hasla',
          displayName: 'Zmień hasło'
        });
      }

      if(this.impersonating) {
        menu.push({
          // return is obfuscated name for 'stop impersonation'
          action: 'return',
          displayName: 'Powrót',
          class: 'text-red-200'
        });
      }

      menu.push({
        action: 'logout',
        displayName: 'Wyloguj'
      });

      return menu;
    },
    topBarRef(): HTMLElement {
      return this.$el as HTMLElement;
    }
  }
});
