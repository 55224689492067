import {NuxtAxiosInstance} from '@nuxtjs/axios';
import {AppConfig} from '~/etc/env';
import {ApiResponse, BaseApiClient} from '~/lib/api/base/BaseApiClient';
import {FetchPage} from '~/lib/api/types/FetchPage';
import {PageResponse} from '~/lib/api/types/PageResponse';
import { ErrorResponse } from '../types/errors/ErrorResponse';


export default class ChildApiClient extends BaseApiClient {
  private readonly sortSeed: number;

  constructor($axios: NuxtAxiosInstance, appConfig: AppConfig) {
    super($axios, appConfig);
    this.sortSeed = Math.random()
  }

  getList(fetchPage: FetchPage) {
    const seed = this.sortSeed
    return this.$get<PageResponse<Child>>(
      `/children/?page=${fetchPage.page}&page_size=${fetchPage.page_size}&search=${fetchPage.search}&seed=${seed}`,
    )
  }

  getDetail(childId: number) {
    return this.$get<Child>(`/children/${childId}/`)
  }

  getChildBySlug(slug: string) {
    return this.$get<Child>(`/children/slug:${slug}/`)
  }

  updateChild(childId: string, firstName: string, lastName: string, childNotes: string) {
    const data = {
      first_name: firstName,
      last_name: lastName,
      notes: childNotes,
    }
    return this.$patch<Child>(`/children/${childId}/`, data)
  }

  async getChildDocumentsStatus() {
    return this.$get<ChildDocumentsStatusResponse>(`/child-documents/status/`)
  }

  sendChildDocuments(documents: ChildDocuments) {
    const data = new FormData()

    if(!documents.bank_account_number) throw new Error('bank_account_number must be present');
    if(!documents.legal_guardian_first_name) throw new Error('legal_guardian_first_name must be present');
    if(!documents.legal_guardian_last_name) throw new Error('legal_guardian_last_name must be present');
    if(!documents.representation_type) throw new Error('representation_type must be present');
    if(!documents.agreement_to_participate_in_program) throw new Error('agreement_to_participate_in_program must be present');
    if(!documents.certificate_of_disability_or_chronic_disease) throw new Error('certificate_of_disability_or_chronic_disease must be present');

    data.append('bank_account_number', documents.bank_account_number.replace(/\s+/g, ''));
    data.append('legal_guardian_first_name', documents.legal_guardian_first_name);
    data.append('legal_guardian_last_name', documents.legal_guardian_last_name);
    data.append('representation_type', documents.representation_type);
    data.append('agreement_to_participate_in_program', documents.agreement_to_participate_in_program);
    data.append('certificate_of_disability_or_chronic_disease', documents.certificate_of_disability_or_chronic_disease);

    if(documents.authorization_to_represent_person) {
      data.append('authorization_to_represent_person', documents.authorization_to_represent_person);
    }

    return this.$postFormData(`/child-documents/`, data)
  }

  updatePhoto(childId: number, imageFile: File | undefined): Promise<ApiResponse<Child>> {
    if (imageFile) {
      const data = new FormData()
      data.append('photo', imageFile)
      return this.$patchFormData<Child>(`/children/${childId}/`, data)
    } else {
      // TODO: deprecated, use deletePhoto instead
      return this.$post<Child>(`/children/${childId}/delete_photo/`)
    }
  }

  deletePhoto(childId: number): Promise<ApiResponse<Child>> {
    return this.$post<Child>(`/children/${childId}/delete_photo/`);
  }

  uploadGalleryPhoto(childId: number, imageFile: File): Promise<ApiResponse<Child>> {
    const data = new FormData()
    data.append('photo', imageFile)
    return this.$post<Child>(`/children/photo-gallery/${childId}/1`, data)
  }

  deleteGalleryPhoto(childId: number, photo_id: number): Promise<ApiResponse<Child>> {
    return this.$delete<Child>(`/children/photo-gallery/${childId}/${photo_id}`)
  }
}
