
import Vue, {PropOptions} from 'vue';
// import {NuxtLink} from '@nuxtjs/';
import {vueWindowSize, vueWindowSizeMixin} from 'vue-window-size';
import LogoutButton from '~/components/Auth/LogoutButton.vue'
import ReturnButton from '~/components/Auth/ReturnButton.vue'
import IconWithAlert from '~/components/Icon/IconWithAlert.vue'

type To = {
  name?: string,
  hash?: string
}

type MenuItem = {
  displayName: string;
  to: To;
  hash?: string;
  alert?: boolean;
  class?: string;
  style?: string;
} | {
  displayName: string;
  onClick: (e: Event) => void;
  alert?: boolean;
  class?: string;
  style?: string;
  component?: string;
  props?: Record<string, any>;
}

export default Vue.extend({
  name: 'MenuDropdown',
  components: {LogoutButton, ReturnButton, IconWithAlert},
  props: {
    menuItems: {
      type: Array,
      default: () => []
    } as PropOptions<MenuItem[]>,
    menuButton: {
      type: Object,
      required: true,
    } as PropOptions<MenuItem>,
    menuDropdownVisible: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      dropdownVisible: (this.menuDropdownVisible || false) as boolean,
      menuButtonId: `menu-button-${Math.random().toString(36).substring(7)}`,
    }
  },
  watch: {
    menuDropdownVisible: {
      immediate: true,
      handler(val) {
        this.dropdownVisible = val;
      }
    },
    dropdownVisible(val) {
      this.$emit('update:menuDropdownVisible', val);
    }
  },
  mounted() {
  },
  computed: {
    anyMenuItemCurrent() {
      return this.menuItems.some((item: MenuItem) => 'to' in item && this.$route.name === (item.to.name ?? ''));
    }
  },
  methods: {
    getVbind(item: MenuItem) {
      if('to' in item) {
        return {to: item.to, class: item.class, style: item.style};
      } else if('onClick' in item) {
        return {class: item.class, style: item.style, ...(item.props ?? {})};
      } else {
        return {};
      }
    },
    isTouchScreen() {
      return window.matchMedia("(pointer: coarse)").matches
    },
    onMouseOver(e: Event) {
      if(! this.isTouchScreen()) {
        console.log(`onMouseOver [${e.constructor.name} / ${this.dropdownVisible} -> true]`);
        e.preventDefault();
        e.stopPropagation();
        this.dropdownVisible = true;
      }
    },
    onMouseLeave(e: Event) {
      if(! this.isTouchScreen()) {
        console.log(`onMouseLeave [${e.constructor.name} / ${this.dropdownVisible} -> false]`);
        e.preventDefault();
        e.stopPropagation();
        this.dropdownVisible = false;
      }
    },
    getComponent(item: MenuItem) {
      if('to' in item) {
        return 'nuxt-link';
      } else {
        return 'button';
      }
    },
    showDropdown(e: Event) {
      console.log(`showDropdown [${e.constructor.name} / ${this.dropdownVisible} -> true]`);
      e.stopPropagation();
      e.preventDefault();
      this.dropdownVisible = true;
    },
    hideDropdown(e: Event) {
      console.log(`hideDropdown [${e.constructor.name} / ${this.dropdownVisible} -> false]`);
      e.stopPropagation();
      e.preventDefault();
      this.dropdownVisible = false;
    },
    toggleDropdown(e: Event) {
      console.log(`toggleDropdown [${e.constructor.name} / ${this.dropdownVisible} -> ${!this.dropdownVisible}]`);
      e.stopPropagation();
      e.preventDefault();
      this.dropdownVisible = !this.dropdownVisible;
    },
    onNavigation(e: Event) {
      console.log(`onNavigation [${e.constructor.name} / ${this.dropdownVisible} -> false]`);
      this.dropdownVisible = false;
      this.$emit('navigation')
    },
  },
  beforeRouteLeave(to, from, next) {
    this.dropdownVisible = false;
    next();
  }
})
