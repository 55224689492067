// import * as faker from 'faker';
import _ from 'lodash';
import Vue from 'vue';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {isMobile} from 'mobile-device-detect';
import {AppConfig} from '~/etc/env';
import {childService} from '~/lib/accessors/api';
import {emptyFetchPage, FetchPage} from '~/lib/api/types/FetchPage';
import ChildView from '~/lib/types/ChildView';
import {picsum} from '~/lib/util/picsum';
import ErrorBroker from '~/store/ErrorBroker';


const APP_CONFIG: AppConfig = process.env.appConfig as unknown as AppConfig;
const {env}                 = APP_CONFIG;

export type ErrorCatcherParentGetter = {
  vm: () => Vue | undefined
}

export const ErrorCatcherParentGetterInit: () => ErrorCatcherParentGetter = () => ({vm: () => undefined})

export type InitialCollectionStoreObj = {
  fetchPage?: FetchPage | undefined
  errorCatcher?: ErrorCatcherParentGetter

  // Is true when we search from beginning (e.g. by typing something in search bar)
  isFirstSearch?: boolean
}

export type NextPageOpts = {
  isFirstSearch?: boolean
}

const defaultFetchPage = () => emptyFetchPage(8);

@Module({
  name: 'ChildSearch',
  stateFactory: true,
  namespaced: true,
  // dynamic: true,
  // store: store
})
export default class ChildSearch extends VuexModule {
  children: ChildView[] = []
  fetchPage: FetchPage  = defaultFetchPage()
  hasNextPage: boolean  = true

  errorCatcher: ErrorCatcherParentGetter = {
    vm: () => undefined,
  }

  // errorBroker: ErrorBroker | undefined = undefined
  //
  // @Mutation
  // SET_ERROR_BROKER(errorBroker: ErrorBroker) {
  //   this.errorBroker = errorBroker;
  // }

  @Action
  initializeChildSearchStore(obj: InitialCollectionStoreObj) {
    let {fetchPage, errorCatcher, isFirstSearch} = _.clone(obj);
    fetchPage                     = _.cloneDeep(fetchPage) || defaultFetchPage();
    fetchPage.page                = 0;
    this.setFetchPage(fetchPage);
    this.setErrorCatcher(errorCatcher ? _.clone(errorCatcher) : {vm: () => undefined})

    this.clear();
    this.getNextPage({isFirstSearch: isFirstSearch || false});
  }

  @Action({rawError: true})
  async getNextPage({isFirstSearch}: NextPageOpts = {}) {
    if (!this.hasNextPage) {
      console.warn('no next page for ChildSearch');
      return;
    }

    this.incrPage()

    try {
      if (env == 'mock') {
        return this.addChildren(fakeChildren(this.fetchPage));
      }
      const page = await childService
        .withErrorBroker()
        .getList(this.fetchPage)

      this.setHasNextPage(!!page.next)
      const children = page.results.map(c => toChildView(c));

      // Fix KIPR-33
      // -----------
      // Fixes race condition when two requests are pending and we duplicate results in the output.
      // Otherwise we could consider some kind of Set instead of list of children.
      if(isFirstSearch) {
        this.clear();
      }

      this.addChildren(children);
    } catch (err) {
      ErrorBroker.CatchError(err, this.errorCatcher.vm());
    }
  }

  @Action
  search(searchText: string) {
    this.setSearchText(searchText);
    this.initializeChildSearchStore({fetchPage: this.fetchPage, errorCatcher: this.errorCatcher, isFirstSearch: true})
  }

  @Mutation
  setSearchText(searchText: string) {
    this.fetchPage.search = searchText;
  }

  @Mutation
  addChildren(list: ChildView[]): void {
    this.children = this.children.concat(list);
  }

  @Mutation
  private clear(): void {
    this.hasNextPage = true;
    this.children    = [];
    // this.errorCatcher = undefined;
  }

  @Mutation
  reset(): void {
    this.hasNextPage  = true;
    this.children     = [];
    this.errorCatcher = {vm: () => undefined};
  }

  @Mutation
  setFetchPage(fetchPage: FetchPage) {
    this.fetchPage = fetchPage;
  }

  @Mutation
  setErrorCatcher(errorCatcher: ErrorCatcherParentGetter) {
    this.errorCatcher = errorCatcher;
  }

  @Mutation
  incrPage() {
    this.fetchPage.page++;
  }

  @Mutation
  setHasNextPage(hasNextPage: boolean) {
    this.hasNextPage = hasNextPage;
  }
}

export function fakeChild(): ChildView {
  return {
    // @ts-ignore
    id: faker.datatype.number(1000000),
    // @ts-ignore
    slug: faker.lorem.slug(2),
    // @ts-ignore
    fullName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    // photoUrl: `${faker.image.avatar()}`,
    photoUrl: picsum(1024, 1024, true),
    hasPhoto: true,
    photoThumbUrl: isMobile ? picsum(1024, 1024, true) : picsum(200, 200, true),
    photoGallery: [],
    // @ts-ignore
    description: faker.lorem.paragraphs(2),
    nuxtLink: {
      to: {
        name: 'podopieczny-slug',
        params: {
          // @ts-ignore
          slug: `${faker.lorem.slug(2)}`,
        },
      },
    },
  };
}

function fakeChildren(fetchPage: FetchPage): ChildView[] {
  // @ts-ignore
  return faker.datatype.array(fetchPage.page_size - 1).map(i => {
    return fakeChild()
  })
}


export function toChildView(result: Child): ChildView {
  let hasPhoto = !!result.photo;
  if (result.photo_thumb === '/dummy.png') {
    result.photo               = '/images/components/profile-avatar-dummy.webp';
    result.photo_thumb         = result.photo;
    result.photo_thumb_desktop = result.photo;
    result.photo_thumb_mobile  = result.photo;
  }

  return {
    id: result.id,
    slug: result.slug,
    fullName: `${result.first_name} ${result.last_name}`,
    photoUrl: result.photo,
    hasPhoto: hasPhoto,
    photoThumbUrl: isMobile ? result.photo_thumb_mobile : result.photo_thumb_desktop,
    description: result.notes,
    photoGallery: result.gallery_photos,
    nuxtLink: {to: {name: 'podopieczny-slug', params: {slug: result.slug + ''}}},
  }
}

// export function fromChildView(child: ChildView): Child {
//   if(child.photo_thumb === '/dummy.png') {
//     child.photo_thumb = '/images/components/profile-avatar-dummy.webp';
//   }
//
//   return {
//     id: child.id,
//     fullName: `${child.first_name} ${child.last_name}`,
//     photoUrl: child.photo_thumb,
//     description: child.notes,
//     nuxtLink: {to: {name: "podopieczny-slug", params: {slug: child.id+''}}},
//   }
// }
