import {BaseServiceWithContext} from '~/lib/api/base/BaseServiceWithContext';
import ChildApiClient from '~/lib/api/clients/ChildApiClient';
import {GetServiceMethods} from '~/lib/api/services/WrapperTypes';

import {handleErrors} from '~/lib/api/types/errors/handleErrors';
import {FetchPage} from '~/lib/api/types/FetchPage';
import {PageResponse} from '~/lib/api/types/PageResponse';
import {toChildView} from '~/store/ChildSearch';
import { ApiResponse } from '../base/BaseApiClient.js';
import ChildView from '~/lib/types/ChildView.js';


export class ChildServiceWithContext extends BaseServiceWithContext<ChildApiClient>
  implements GetServiceMethods<ChildApiClient, 'getChildDocumentsStatus' | 'login'> {

  @handleErrors()
  getList(fetchPage: FetchPage) {
    return this.apiClient.getList(fetchPage) as Promise<PageResponse<Child>>;
  }

  @handleErrors()
  getDetail(childId: number) {
    return this.apiClient.getDetail(childId) as Promise<Child>;
  }

  @handleErrors()
  getChildBySlug(slug: string) {
    return this.apiClient.getChildBySlug(slug) as Promise<Child>;
  }

  @handleErrors()
  updateChild(childId: string, firstName: string, lastName: string, childNotes: string) {
    return this.apiClient.updateChild(childId, firstName, lastName, childNotes) as Promise<Child>;
  }

  @handleErrors()
  getChildDocumentsStatus() {
    return this.apiClient.getChildDocumentsStatus() as Promise<ChildDocumentsStatusResponse>;
  }

  @handleErrors()
  sendChildDocuments(documents: ChildDocuments) {
    return this.apiClient.sendChildDocuments(documents);
  }

  @handleErrors({map: toChildView})
  async updatePhoto(childId: number, imageFile: File | undefined): Promise<ChildView> {
    // @ts-ignore
    return this.apiClient.updatePhoto(childId, imageFile);
  }

  @handleErrors()
  async deletePhoto(childId: number) {
    const child: Child = await (this.apiClient.deletePhoto(childId) as Promise<Child>);
    return toChildView(child);
  }

  @handleErrors()
  async uploadGalleryPhoto(childId: number, imageFile: File) {
    const child: Child = await (this.apiClient.uploadGalleryPhoto(childId, imageFile) as Promise<Child>);
    return toChildView(child);
  }

  @handleErrors()
  async deleteGalleryPhoto(childId: number, photo_id: number) {
    const child: Child = await (this.apiClient.deleteGalleryPhoto(childId, photo_id) as Promise<Child>);
    return toChildView(child);
  }
}
